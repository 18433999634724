// Menu JS starts here
export const initOffcanvasMenu = () =>{
    $('.js-nav-btn, .js-off-canvas-overlay').on('click', function(){
        $('.js-body').toggleClass('is-offcanvas-open');

        if(!$('.js-body').hasClass('is-offcanvas-open')) {
          $('.js-main-nav-item').removeClass('is-child-open');
        }
    })

    const link = $('.js-main-nav-item > a');
    link.on('click', function(e){
      e.preventDefault();
      link.not($(this)).parent().removeClass('is-child-open');
      $(this).parent().toggleClass('is-child-open');
    })
}
// Menu JS end here