import '@fancyapps/fancybox';

const initFancyBox = () => {
    $("[data-fancybox]").fancybox({
        thumbs: false,
        hash: false,
        loop: true,
        protect: true,
        transitionEffect: "fade",
        buttons: [
            'close'
        ]
    });
}
export default initFancyBox;