import { Foundation } from 'foundation-sites/js/foundation.core';
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import {Accordion} from 'foundation-sites/js/foundation.accordion';
import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import 'what-input';

Foundation.addToJquery($);
Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(Equalizer, 'Equalizer');
Foundation.plugin(Interchange, 'Interchange');
Foundation.onImagesLoaded = onImagesLoaded;
Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
$(document).foundation();

import {initOffcanvasMenu} from './plugins/menu';
import {initAutoHideNav} from './plugins/autohide';
import initBackTop from './plugins/backtop';
import {initFooterSpecing, initCookies} from './plugins/base';
import {initImageLazyload} from './plugins/lazyload';
import initFancyBox from './plugins/fancybox';
import initAnimation from './plugins/animation';
import {initMouseScrollOpacity} from './plugins/mouse';
import {initRegularCarousel, initRegularCarouselItemTwo, initCardCarousel} from './plugins/carousel';
import initCounters from './plugins/counter';


window.addEventListener("load", function () {
    initCookies();

});

document.addEventListener('DOMContentLoaded', () => {
    initFooterSpecing();
    initOffcanvasMenu();
    initAutoHideNav();
    initBackTop();
    initImageLazyload();
    initFancyBox();
    initAnimation();
    initRegularCarousel();
    initCounters();
    initRegularCarouselItemTwo();
    initCardCarousel();
})

$(window).on('resize', function(){
    initFooterSpecing();
})

$(window).on('scroll', function(){
    initMouseScrollOpacity();
})