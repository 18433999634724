import Headroom from 'headroom.js';

export const initAutoHideNav = () => {
    var header = document.querySelector('#js-header-auto-hide');

    new Headroom(header, {
        tolerance: {
            down: 2,
            up: 5
        },
        offset: 100,
        classes: {
            initial: 'slide',
            pinned: 'slideReset',
            unpinned: 'slideUp'
        }
    }).init();
} 