export const initFooterSpecing = () => {
    let height = $('.js-footer').outerHeight();
    $('.js-page-wrap').css({
        'margin-bottom': height
    });
}

export const initCookies = () => {
    if (window.cookieconsent != null) {
        function cookieConsentJSLoader(cookieconsent) {
            if (cookieconsent.hasConsented()) {
                var script = document.createElement("script");
                script.src = "https://www.googletagmanager.com/gtag/js?id=UA-50634203-1";
                script.onload = function () {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'UA-50634203-1', { 'anonymize_ip': true });
                }
                document.head.appendChild(script);
            }
        }

        var custom_cc_css = document.createElement('style');
        custom_cc_css.type = 'text/css';
        custom_cc_css.innerHTML = 'div.cc-hidden-revoke { display: none !important; visibility: hidden; }';
        document.getElementsByTagName('head')[0].appendChild(custom_cc_css);

        var cookieConsentConfig = {

            "theme": "edgeless",
            "position": "bottom-left",
            "type": "opt-in",
            "revokeBtn": '<div class="cc-hidden-revoke"></div>',
            "revokable": false,
            "cookie": {
                "expiryDays": 60,
            },
            "law": {
                "regionalLaw": false,
            },
            "location": false,
            "onInitialise": function (status) {
                cookieConsentJSLoader(this);
            },
            "onStatusChange": function (status, chosenBefore) {
                cookieConsentJSLoader(this);
            }
        };

        switch (document.documentElement["lang"]) {
            case "de":
                cookieConsentConfig["content"] = {
                    header: 'Cookies used on the website!',
                    message: 'Wir nutzen Cookies, okay? <br> ',
                    dismiss: 'Got it!',
                    allow: 'OK',
                    deny: 'Ablehnen',
                    link: 'Wie bitte?',
                    href: '../de/datenschutz',
                    close: '&#x274c;',
                    policy: 'Cookie Policy',
                    target: '_blank',
                };
                break;
            case "en":
            default:
                cookieConsentConfig["content"] = {
                    header: 'Cookies used on the website!',
                    message: 'This site uses cookies, okay?',
                    dismiss: 'Got it!',
                    allow: 'Yes',
                    deny: 'No',
                    link: 'What?',
                    href: '../en/privacy',
                    close: '&#x274c;',
                    policy: 'Cookie Policy',
                    target: '_blank',
                };
        }
        window.cookieconsent.initialise(cookieConsentConfig, function (ccPopup) {
            var revokeBtn = document.getElementById("cc-custom-revoke-btn");
            if (revokeBtn != null)
                revokeBtn.addEventListener("click", function (e) {
                    ccPopup.clearStatus();
                    ccPopup.open();
                    e.preventDefault();
                }, false);
        });
    }
}