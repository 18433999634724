import 'slick-carousel';

export const initRegularCarousel = () => {
    $('.js-regular-carousel').slick({
        dots: true,
        swipe: true,
        autoplay: false,
        infinite: true,
        lazyLoad: 'progressive', // ondemand progressive anticipated
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

export const initRegularCarouselItemTwo = () => {
    $('.js-regular-carousel-item-two').slick({
        dots: true,
        swipe: true,
        autoplay: false,
        infinite: true,
        lazyLoad: 'progressive', // ondemand progressive anticipated
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

export const initCardCarousel = () => {
    $('.js-card-carousel').each(function(){
        $(this).slick({
            dots: true,
            arrows: true,
            swipe: false,
            autoplay: false,
            infinite: true,
            lazyLoad: 'progressive', // ondemand progressive anticipated
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $(this).find('.js-card-carousel-prev'),
            nextArrow: $(this).find('.js-card-carousel-next'),
        });
    })
    
}